import { Block } from 'baseui/block'

import { ParagraphSmall } from 'baseui/typography'

import { Modal } from 'baseui/modal'
import StyledSpinner from 'components/shared/styled-spinner'
import { Plus } from '@phosphor-icons/react'
import React, { useCallback, useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import Button from 'components/ui/specific/PrimaryButton'
import Header from 'components/ui/generic/Header'
import { Column } from './DroppableColumn'
import {
  CreateCustomCancelReasonButtonContainer,
  DraggableColumnGrid
} from './CustomCancelReasonsContainer.styled'
import { CustomCancelReasonForm } from './CustomCancelReasonForm'
import { useCustomCancelReasonsContext } from 'components/contexts/custom-cancel-reason.context'

import Tabs from 'components/ui/generic/Tabs'
import { Tab } from 'baseui/tabs'
import { REASON_TYPES } from 'components/constants/cancel_reasons'

export const CustomCancelReasonsContainer = () => {
  const {
    reasonType,
    loading,
    customCancelReason: { isFormOpen, selectedCustomCancelReason },
    columns: { available, selected },
    actions: { setFormOpen, onDragEnd, setSelectedCustomCancelReason, setReasonType }
  } = useCustomCancelReasonsContext()

  const { t } = useTranslation()
  const [activeKey, setActiveKey] = useState<string>(REASON_TYPES.cancel)

  const closeModal = useCallback(() => {
    setFormOpen(false)
    setSelectedCustomCancelReason(undefined)
  }, [])

  return (
    <>
      <Header
        title={t('Settings.CustomCancelReasons.Header.Text')}
        rightItems={[
          <CreateCustomCancelReasonButtonContainer>
            <Button
              data-testid={'cancel-reason-create-button'}
              onClick={() => {
                setFormOpen(true)
              }}
              startEnhancer={() => <Plus />}
              size="compact">
              {t('Settings.CustomCancelReasons.Buttons.NewCustomCancelReason.Text')}
            </Button>
          </CreateCustomCancelReasonButtonContainer>
        ]}
      />

      <Tabs onChange={params => setReasonType(params.activeKey.toString())} activeKey={reasonType}>
        <Tab
          title={t('Settings.CustomCancelReasons.HeaderCancelReasons.Text')}
          key={REASON_TYPES.cancel}>
          {loading ? (
            <StyledSpinner />
          ) : (
            <>
              <ParagraphSmall margin="0 0 16px">
                {t('Settings.CustomCancelReasons.SubTitleCancel.Text')}
              </ParagraphSmall>
              <DragDropContext onDragEnd={onDragEnd} margin="0">
                <DraggableColumnGrid>
                  <Column col={available} key={available.id} />
                  <Column col={selected} key={selected.id} />
                </DraggableColumnGrid>
              </DragDropContext>
            </>
          )}
        </Tab>
        <Tab
          title={t('Settings.CustomCancelReasons.HeaderDeclineReasons.Text')}
          key={REASON_TYPES.decline}>
          {loading ? (
            <StyledSpinner />
          ) : (
            <>
              <ParagraphSmall margin="0 0 16px">
                {t('Settings.CustomCancelReasons.SubTitleDecline.Text')}
              </ParagraphSmall>
              <DragDropContext onDragEnd={onDragEnd} margin="0">
                <DraggableColumnGrid>
                  <Column col={available} key={available.id} />
                  <Column col={selected} key={selected.id} />
                </DraggableColumnGrid>
              </DragDropContext>
            </>
          )}
        </Tab>
      </Tabs>
      <Modal isOpen={isFormOpen} onClose={closeModal} closeable animate autoFocus>
        <CustomCancelReasonForm id={selectedCustomCancelReason?.id} onClose={closeModal} />
      </Modal>
    </>
  )
}
