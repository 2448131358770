import React, { useEffect, useReducer } from 'react'
import { createContext } from 'components/utils/create-context'
import { CustomCancelReason } from 'components/models/CustomCancelReason'
import { useTranslation } from 'react-i18next'
import {
  CustomCancelReasonReducer,
  RECALCULATE_COLUMNS,
  SET_ALL_CANCEL_REASONS,
  SET_COLUMNS,
  SET_FORM_OPEN,
  SET_LOADING,
  SET_REASON_TYPE,
  SET_SAVING,
  SET_SELECTED_CUSTOM_CANCEL_REASON
} from '../reducers/custom_cancel_reason.reducer'
import { fancyToast } from '../utils'
import {
  createCustomCancelReason,
  fetchActiveCancelReasons,
  fetchAllCancelReasons,
  saveAssignedCancelReasons,
  updateCustomCancelReason
} from '../services/custom_cancel_reason.service'
import { StatusCodes } from 'components/constants/http-status-codes'
import { REASON_TYPES } from 'components/constants/cancel_reasons'

export type DroppableColumn = {
  id: string
  title: string
  list: CustomCancelReason[]
}

export const selectedColumnId = 'selected'
export const availableColumnId = 'available'

export type CustomCancelReasonState = {
  reasonType: string
  loading: boolean
  customCancelReason: {
    selectedCustomCancelReason?: CustomCancelReason
    isFormOpen: boolean
  }
  saving: boolean
  availableCancelReasons: CustomCancelReason[]
  activeCancelReasons: CustomCancelReason[]
  columns: {
    available: DroppableColumn
    selected: DroppableColumn
  }
}

export const [useCustomCancelReasonsContext, CustomCancelReasonsContextProvider] = createContext()

export const CustomCancelReasonsProvider = ({ children }) => {
  const { t } = useTranslation()

  const initialState: CustomCancelReasonState = {
    reasonType: REASON_TYPES.cancel,
    loading: true,
    customCancelReason: {
      selectedCustomCancelReason: null,
      isFormOpen: false
    },
    saving: false,
    availableCancelReasons: [],
    activeCancelReasons: [],
    columns: {
      available: {
        id: availableColumnId,
        title: t('Settings.CustomCancelReasons.DragAndDrop.Columns.Available.Text'),
        list: []
      },
      selected: {
        id: selectedColumnId,
        title: t('Settings.CustomCancelReasons.DragAndDrop.Columns.Active.Text'),
        list: []
      }
    }
  }

  const [state, dispatch] = useReducer(CustomCancelReasonReducer, initialState)

  useEffect(() => {
    loadAllCancelReasons()
  }, [state.reasonType])

  useEffect(() => {
    recalculateColumns()
  }, [state.activeCancelReasons, state.availableCancelReasons])

  const loadAllCancelReasons = async () => {
    setLoading(true)
    const [allCancelreason, activeCancelReason] = await Promise.all([
      fetchAllAvailableCancelReasons(state.reasonType),
      fetchAllActiveCancelReasons(state.reasonType)
    ])
    dispatch({
      type: SET_ALL_CANCEL_REASONS,
      payload: {
        availableCancelReasons: allCancelreason,
        activeCancelReasons: activeCancelReason
      }
    })
    await recalculateColumns()
  }

  const fetchAllAvailableCancelReasons = async (reasonType: string) => {
    const [data, status] = await fetchAllCancelReasons(reasonType)
    return status === StatusCodes.OK ? data : []
  }

  const fetchAllActiveCancelReasons = async (reasonType: string) => {
    const [data, status] = await fetchActiveCancelReasons(reasonType)
    return status === StatusCodes.OK ? data : []
  }

  const syncAssigned = (cancelReasons: string[]) => {
    setLoading(true)
    saveAssignedCancelReasons(cancelReasons, state.reasonType)
      .then(([data, status]) => {
        if (status < 300) {
          fancyToast({ info: t('Common.Info.Saved.Default.Text') }, status)
        } else {
          fancyToast({ error: t('Common.Errors.Default.Text') }, status)
        }
      })
      .then(async () => {
        await loadAllCancelReasons()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const recalculateColumns = async () => {
    dispatch({
      type: RECALCULATE_COLUMNS,
      payload: { all: state.availableCancelReasons, assigned: state.activeCancelReasons }
    })
  }

  const setColumns = columns => {
    dispatch({ type: SET_COLUMNS, payload: columns })
  }

  const onDragEnd = ({ source, destination }) => {
    if (destination === undefined || destination === null) return null
    if (source.droppableId === destination.droppableId && destination.index === source.index)
      return null

    const start = state.columns[source.droppableId]
    const end = state.columns[destination.droppableId]

    if (start === end) {
      const newList = start.list.filter((_: any, idx: number) => idx !== source.index)

      newList.splice(destination.index, 0, start.list[source.index])

      const newCol = {
        id: start.id,
        title: start.title,
        list: newList
      }

      setColumns({ ...state.columns, [newCol.id]: newCol })
      if (start?.id !== availableColumnId) syncAssigned(newList.map(type => type.id))
      return null
    } else {
      const newStartList = start.list.filter((_: any, idx: number) => idx !== source.index)

      const newStartCol = {
        id: start.id,
        title: start.title,
        list: newStartList
      }

      const newEndList = end.list

      newEndList.splice(destination.index, 0, start.list[source.index])

      const newEndCol = {
        id: end.id,
        title: end.title,
        list: newEndList
      }

      setColumns({
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol
      })

      const selectedColumn = newStartCol.id === state.columns.selected.id ? newStartCol : newEndCol
      syncAssigned(selectedColumn.list.map(type => type.id))
      return null
    }
  }

  const setFormOpen = (flag: boolean) => {
    dispatch({ type: SET_FORM_OPEN, payload: flag })
  }

  const setLoading = (value: boolean) => {
    dispatch({ type: SET_LOADING, payload: value })
  }

  const setSaving = (value: boolean) => {
    dispatch({ type: SET_SAVING, payload: value })
  }

  const setSelectedCustomCancelReason = (record: CustomCancelReason) => {
    dispatch({ type: SET_SELECTED_CUSTOM_CANCEL_REASON, payload: record })
  }

  const createCancelReason = (record: CustomCancelReason) => {
    setSaving(true)
    const recordFormated = {
      ...record,
      reason: JSON.stringify(record.reason),
      reasonType: state.reasonType
    }
    createCustomCancelReason(recordFormated)
      .then(([data, status]) => {
        if ([200, 304].includes(status) && data) {
          setSelectedCustomCancelReason(null)
          setFormOpen(false)
          loadAllCancelReasons()
          fancyToast(
            {
              info: t('Common.Info.Interpolated.Text', {
                model: t('Common.ModelType.CustomCancelReason.Text'),
                action: t('Common.Actions.Created.Text')
              })
            },
            status
          )
        }
      })
      .finally(() => {
        setSaving(false)
      })
  }

  const updateCancelReason = (record: CustomCancelReason) => {
    setSaving(true)
    const recordFormated = {
      ...record,
      reason: JSON.stringify(record.reason),
      reasonType: state.reasonType
    }
    updateCustomCancelReason(recordFormated)
      .then(([data, status]) => {
        if ([204, 304].includes(status) && data) {
          setSelectedCustomCancelReason(null)
          setFormOpen(false)
          loadAllCancelReasons()
          fancyToast(
            {
              info: t('Common.Info.Interpolated.Text', {
                model: t('Common.ModelType.CustomCancelReason.Text'),
                action: t('Common.Actions.Updated.Text')
              })
            },
            200
          )
        }
      })
      .finally(() => {
        setSaving(false)
      })
  }

  const setReasonType = (reasonType: string) => {
    dispatch({ type: SET_REASON_TYPE, payload: reasonType })
  }

  const actions = {
    createCancelReason,
    updateCancelReason,
    setFormOpen,
    setSelectedCustomCancelReason,
    onDragEnd,
    setReasonType
  }

  return (
    <CustomCancelReasonsContextProvider value={{ ...state, actions }}>
      {children}
    </CustomCancelReasonsContextProvider>
  )
}
