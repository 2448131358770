import React, { Dispatch, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FlexGridItem } from 'baseui/flex-grid'
import { FormControl } from 'baseui/form-control'
import { HeadingXSmall, ParagraphSmall } from 'baseui/typography'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import useSearchClient from 'components/shared/hooks/use-search-client'
import Checkbox from 'components/ui/generic/Checkbox'
import Select from 'components/ui/generic/Select'

import { SelectSearch } from 'components/components/SelectSearch'
import { Block } from 'baseui/block'

enum ORDER_VALIDATION_MODE {
  ENABLE_FOR_ALL = 'enable_for_all',
  ENABLE_FOR_SELECTED = 'enable_for_selected',
  ENABLE_FOR_ALL_EXCEPT_SELECTED = 'enable_for_all_except_selected'
}

interface OrderValdationSectionProps {
  appointmentPreference: any
  setAppointmentPreference: Dispatch<any>
  disabled: boolean
}

const OrderValidationSection = ({
  appointmentPreference,
  setAppointmentPreference,
  disabled
}: OrderValdationSectionProps) => {
  const { t } = useTranslation()

  return (
    <div style={{ marginBottom: '10px', width: '100%' }}>
      <HeadingXSmall marginTop="0" marginBottom="scale800">
        {t('AppointmentPreferences.Form.Fields.OrderValidation.Header.Text')}
      </HeadingXSmall>
      <>
        <ParagraphSmall marginTop="0" marginBottom="scale800">
          {t('AppointmentPreferences.Form.Fields.OrderValidation.Subtitle.Validation')}
        </ParagraphSmall>
        <FlexGridItem>
          <FormControl>
            <Checkbox
              disabled={disabled}
              checked={appointmentPreference.validateOrderNumbersForExtUsers}
              onChange={e => {
                setAppointmentPreference({
                  ...appointmentPreference,
                  validateOrderNumbersForExtUsers: e.currentTarget.checked
                })
              }}
              label={t(
                'AppointmentPreferences.Form.Fields.OrderValidation.Fields.ValidateOrderNumbers.Label.Text'
              )}
            />
          </FormControl>
          {appointmentPreference.validateOrderNumbersForExtUsers && (
            <FormControl htmlFor="orderValidationMode">
              <Select
                id="orderValidationMode"
                required
                options={[
                  {
                    label: t(
                      'AppointmentPreferences.Form.Fields.OrderValidation.Fields.OrderValidationMode.EnableForAll'
                    ),
                    id: ORDER_VALIDATION_MODE.ENABLE_FOR_ALL
                  },
                  {
                    label: t(
                      'AppointmentPreferences.Form.Fields.OrderValidation.Fields.OrderValidationMode.EnableForSelected'
                    ),
                    id: ORDER_VALIDATION_MODE.ENABLE_FOR_SELECTED
                  },
                  {
                    label: t(
                      'AppointmentPreferences.Form.Fields.OrderValidation.Fields.OrderValidationMode.EnableForAllExceptSelected'
                    ),
                    id: ORDER_VALIDATION_MODE.ENABLE_FOR_ALL_EXCEPT_SELECTED
                  }
                ]}
                value={[{ id: appointmentPreference?.orderValidationMode }]}
                placeholder=""
                searchable={false}
                clearable={false}
                onChange={params => {
                  setAppointmentPreference({
                    ...appointmentPreference,
                    orderValidationMode: params.option.id as string
                  })
                }}
              />
            </FormControl>
          )}
          {appointmentPreference.validateOrderNumbersForExtUsers &&
            appointmentPreference.orderValidationMode != ORDER_VALIDATION_MODE.ENABLE_FOR_ALL && (
              <Block>
                <FormControl>
                  <SelectSearch
                    multi
                    record={appointmentPreference}
                    entity="schedulers"
                    onValuesChange={ids =>
                      setAppointmentPreference({
                        ...appointmentPreference,
                        schedulers: ids
                      })
                    }
                  />
                </FormControl>
              </Block>
            )}
        </FlexGridItem>
        <FlexGridItem>
          <FormControl>
            <Checkbox
              disabled={disabled}
              checked={appointmentPreference.proceedWithoutMatchingOrder}
              onChange={e => {
                setAppointmentPreference({
                  ...appointmentPreference,
                  proceedWithoutMatchingOrder: e.currentTarget.checked
                })
              }}
              label={t(
                'AppointmentPreferences.Form.Fields.OrderValidation.Fields.AllowToProceedWithoutMatchingOrder.Label.Text'
              )}
            />
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl>
            <Checkbox
              disabled={disabled}
              checked={appointmentPreference.allowOpenOrdersOnly}
              onChange={e => {
                setAppointmentPreference({
                  ...appointmentPreference,
                  allowOpenOrdersOnly: e.currentTarget.checked
                })
              }}
              label={t(
                'AppointmentPreferences.Form.Fields.OrderValidation.Fields.OnlyAllowOpenOrders.Label.Text'
              )}
            />
          </FormControl>
        </FlexGridItem>
      </>
      <>
        <ParagraphSmall marginTop="0" marginBottom="scale800">
          {t('AppointmentPreferences.Form.Fields.OrderValidation.Subtitle.Display')}
        </ParagraphSmall>
        <FormControl>
          <Checkbox
            disabled={disabled}
            checked={appointmentPreference.showQuantityOnAppointmentCreation}
            onChange={e => {
              setAppointmentPreference({
                ...appointmentPreference,
                showQuantityOnAppointmentCreation: e.currentTarget.checked
              })
            }}
            label={t(
              'AppointmentPreferences.Form.Fields.OrderValidation.Fields.DisplayQuantity.Label.Text'
            )}
          />
        </FormControl>
        <FormControl>
          <Checkbox
            disabled={disabled}
            checked={appointmentPreference.showWeightOnAppointmentCreation}
            onChange={e => {
              setAppointmentPreference({
                ...appointmentPreference,
                showWeightOnAppointmentCreation: e.currentTarget.checked
              })
            }}
            label={t(
              'AppointmentPreferences.Form.Fields.OrderValidation.Fields.DisplayWeight.Label.Text'
            )}
          />
        </FormControl>
        <FormControl>
          <Checkbox
            disabled={disabled}
            checked={appointmentPreference.showDeliveryInstructionsOnAppointmentCreation}
            onChange={e => {
              setAppointmentPreference({
                ...appointmentPreference,
                showDeliveryInstructionsOnAppointmentCreation: e.currentTarget.checked
              })
            }}
            label={t(
              'AppointmentPreferences.Form.Fields.OrderValidation.Fields.DisplayDeliveryInstructions.Label.Text'
            )}
          />
        </FormControl>
      </>
    </div>
  )
}

export default OrderValidationSection
