import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal'
import { FormControl } from 'baseui/form-control'
import { Block } from 'baseui/block'
import Select from 'components/ui/generic/Select'
import { useTranslation } from 'react-i18next'
import { ErrorMessageButton } from 'components/components/ErrorMessageButton'
import { INBOUND, OUTBOUND } from 'components/contexts/appointment-context'
import { Appointment } from '../types'

const BOTH_OPTION = 'both'

interface CancelAppointmentProps {
  isOpen: boolean
  close: (appointmentIds?: string[]) => void
  appointments: {
    inbound: Appointment
    outbound: Appointment
  }
  facility: any
}

const CancelDropAppointment = ({
  isOpen,
  close,
  appointments,
  facility
}: CancelAppointmentProps) => {
  const [cancelOption, setCancelOption] = useState<string>(BOTH_OPTION)
  const [cancelOptions, setCancelOptions] = useState<any[]>([])
  const { t, i18n } = useTranslation()

  const requiredFieldsPresent = [
    {
      label: t('Appointments.CancelAppointmentModal.Validations.Reason.Text'),
      status: !!cancelOption
    }
  ]

  const formatCancelOptions = () => {
    if (facility?.appointmentTypes?.length > 0) {
      const inboundType = facility.appointmentTypes.find(
        apptType => apptType.id === appointments.inbound.appointmentTypeId
      )
      const outboundType = facility.appointmentTypes.find(
        apptType => apptType.id === appointments.outbound.appointmentTypeId
      )

      return [
        {
          label: t('Appointments.CancelAppointmentModal.Fields.CancelOption.CancelBoth.Text'),
          id: BOTH_OPTION
        },
        {
          label: t('Appointments.CancelAppointmentModal.Fields.CancelOption.CancelInbound.Text', {
            name: inboundType?.name
          }),
          id: INBOUND
        },
        {
          label: t('Appointments.CancelAppointmentModal.Fields.CancelOption.CancelOutbound.Text', {
            name: outboundType?.name
          }),
          id: OUTBOUND
        }
      ]
    }
  }

  useEffect(() => {
    if (facility?.appointmentTypes?.length > 0 && appointments.inbound && appointments.outbound) {
      setCancelOptions(formatCancelOptions())
    }
  }, [i18n.language, appointments])

  const onConfirmCancel = () => {
    const cancelIds = []
    if (cancelOption === BOTH_OPTION) {
      cancelIds.push(appointments.inbound.id, appointments.outbound.id)
    } else if (cancelOption === INBOUND) {
      cancelIds.push(appointments.inbound.id)
    } else if (cancelOption === OUTBOUND) {
      cancelIds.push(appointments.outbound.id)
    }

    close(cancelIds)
  }

  return (
    <Modal
      onClose={() => {
        close()
      }}
      isOpen={isOpen}>
      <ModalHeader>{t('Appointments.CancelAppointmentModal.Header.Cancel.Text')}</ModalHeader>
      <ModalBody>
        <FormControl>
          <Select
            id="cancel-option"
            placeholder={''}
            searchable={false}
            clearable={false}
            options={cancelOptions}
            value={cancelOptions.find(option => option.id === cancelOption)}
            onChange={({ option, type }) => {
              setCancelOption(option.id as string)
            }}
          />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Block display="flex" alignItems="center" justifyContent="end">
          <ErrorMessageButton
            errors={requiredFieldsPresent}
            onClick={() => {
              onConfirmCancel()
            }}
            statefulTooltipProps={{ placement: 'top' }}
            label={t('Appointments.CancelAppointmentModal.ConfirmButton.Cancel')}
          />
        </Block>
      </ModalFooter>
    </Modal>
  )
}

export default CancelDropAppointment
