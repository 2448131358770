import React, { useState, useContext, useEffect } from 'react'

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal'
import { fancyToast } from '../../utils'
import { FormControl } from 'baseui/form-control'

import Checkbox from 'components/ui/generic/Checkbox'
import { Block } from 'baseui/block'
import { CurrentUserContext } from '../../homepage/current-user-context'
import Select from 'components/ui/generic/Select'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  fetchActiveCancelReasons,
  fetchActiveCancelReasonsForOpenScheduling,
  cancelAppointment
} from 'components/services/custom_cancel_reason.service'
import { StatusCodes } from 'components/constants/http-status-codes'
import { CustomCancelReason } from 'components/models/CustomCancelReason'
import { OTHER_CANCEL_REASON, REASON_TYPES } from 'components/constants/cancel_reasons'
import Input from 'components/ui/generic/Input'
import { ErrorMessageButton } from 'components/components/ErrorMessageButton'
import { Milestones } from 'components/models/Milestones'
import { milestoneService } from 'components/services'

const formatCustomCancelReasons = (cancelReasons: CustomCancelReason[], language: string) =>
  cancelReasons.map(item => ({
    label: item.reason[language] || '',
    id: item.id
  }))

interface CancelAppointmentProps {
  isOpen: boolean
  close: (deleted?: boolean) => void
  appointment: any
  openScheduling?: boolean
  externalScheduler?: boolean
  appointmentIds?: string[]
  reasonType?: string
}

const CancelAppointment = ({
  isOpen,
  close,
  appointment,
  openScheduling,
  externalScheduler,
  appointmentIds,
  reasonType = REASON_TYPES.cancel
}: CancelAppointmentProps) => {
  const { currentUser } = useContext(CurrentUserContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [cancelReason, setCancelReason] = useState<string>('')
  const [cancelReasonDescription, setCancelReasonDescription] = useState<string>('')
  const [notifyScheduler, setNotifyScheduler] = useState<boolean>(true)
  const [customCancelReasons, setCustomCancelRasons] = useState<any[]>([])
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const otherCancelReasonOption = {
    label: t('Appointments.CancelAppointmentModal.Fields.Reason.OtherOption.Label'),
    id: OTHER_CANCEL_REASON
  }
  const requiredFieldsPresent = [
    {
      label: t(
        `Appointments.CancelAppointmentModal.Validations.Reason.${
          reasonType == REASON_TYPES.decline ? 'Decline' : 'Cancel'
        }`
      ),
      status: !!cancelReason
    },
    {
      label: t(
        `Appointments.CancelAppointmentModal.Validations.ReasonDescription.${
          reasonType == REASON_TYPES.decline ? 'Decline' : 'Cancel'
        }`
      ),
      status: cancelReason != OTHER_CANCEL_REASON || !!cancelReasonDescription
    }
  ]

  useEffect(() => {
    if (currentUser || appointment?.id || appointmentIds[0]) {
      getCustomCancelReasons()
    }
  }, [i18n.language, appointment?.id, appointmentIds])

  const getCustomCancelReasons = async () => {
    const [data, status] =
      openScheduling || externalScheduler
        ? await fetchActiveCancelReasonsForOpenScheduling(appointment?.id || appointmentIds[0])
        : await fetchActiveCancelReasons(reasonType)
    if (status === StatusCodes.OK) {
      const cancelReasonsFormat = formatCustomCancelReasons(
        data.filter(item => item.reason.hasOwnProperty(i18n.language)),
        i18n.language
      )
      setCustomCancelRasons([...cancelReasonsFormat, otherCancelReasonOption])
    }
  }

  const requestCancel = async id => {
    return await cancelAppointment(id, openScheduling, {
      customCancelReasonId: cancelReason === OTHER_CANCEL_REASON ? null : cancelReason,
      cancelReason: cancelReason === OTHER_CANCEL_REASON ? cancelReasonDescription : null,
      notifyScheduler
    })
  }

  const onCancelAppointment = async () => {
    setLoading(true)
    const [json, status] = await requestCancel(appointment?.id)

    if (status == 204) {
      fancyToast(
        {
          info: t('Common.Info.Interpolated.Text', {
            model: t('Common.ModelType.Appointment.Text'),
            action: t('Common.Actions.Cancelled.Text')
          })
        },
        status
      )
      close(true)
      if (openScheduling) {
        setTimeout(() => {
          history.push('/openscheduling')
        }, 1500)
      }
      if (externalScheduler) {
        setTimeout(() => {
          history.push('/appointments')
        }, 2000)
      }
    } else {
      fancyToast(json, status)
    }
    setLoading(false)
  }

  const onDeclineAppointment = async () => {
    const params: Milestones = {
      declinedAt: new Date(),
      appointmentId: appointment?.id,
      facilityId: appointment?.facilityId,
      declineReason: cancelReason === OTHER_CANCEL_REASON ? cancelReasonDescription : null,
      customDeclineReasonId: cancelReason === OTHER_CANCEL_REASON ? null : cancelReason
    }
    const [result, status] = await milestoneService.update(params)
    if (status === StatusCodes.OK || status === StatusCodes.CREATED) {
      fancyToast(
        {
          info: t('Common.Info.Interpolated.Text', {
            model: t('Common.ModelType.Appointment.Text'),
            action: t('Common.Actions.Updated.Text')
          })
        },
        StatusCodes.OK
      )
      close(true)
    } else {
      fancyToast(
        {
          info: t('Common.Errors.Interpolated.Text', {
            model: t('Common.ModelType.Appointment.Text'),
            action: t('Common.Actions.Updated.Text')
          })
        },
        status
      )
    }
  }

  const cancelMultipleAppointments = async () => {
    setLoading(true)
    const requests: Promise<[any, number]>[] = []
    appointmentIds.forEach(id => {
      requests.push(requestCancel(id))
    })
    const response = await Promise.all(requests)
    const error = response.filter(([json, status]) => status !== 204)
    if (error?.length == 0) {
      fancyToast(
        {
          info: t('Common.Info.Interpolated.Text', {
            model: t('Common.ModelType.Appointment.Text'),
            action: t('Common.Actions.Cancelled.Text')
          })
        },
        StatusCodes.NO_CONTENT
      )
      close(true)
      if (openScheduling) {
        setTimeout(() => {
          history.push('/openscheduling')
        }, 1500)
      }
      if (externalScheduler) {
        setTimeout(() => {
          history.push('/appointments')
        }, 2000)
      }
    } else {
      const [json, status] = error[0]
      fancyToast(json, status)
    }
    setLoading(false)
  }

  const onConfirmAction = () => {
    if (reasonType == REASON_TYPES.decline) {
      onDeclineAppointment()
    } else if (appointmentIds) {
      cancelMultipleAppointments()
    } else {
      onCancelAppointment()
    }
  }

  return (
    <Modal
      onClose={() => {
        close()
      }}
      isOpen={isOpen}>
      <ModalHeader>
        {t(
          `Appointments.CancelAppointmentModal.Header.${
            reasonType == REASON_TYPES.decline ? 'Decline' : 'Cancel'
          }.Text`
        )}
      </ModalHeader>
      <ModalBody>
        <FormControl
          label={t(
            `Appointments.CancelAppointmentModal.Caption.${
              reasonType == REASON_TYPES.decline ? 'Decline' : 'Cancel'
            }.Label.Text`
          )}
          caption={t(
            `Appointments.CancelAppointmentModal.Caption.${
              reasonType == REASON_TYPES.decline ? 'Decline' : 'Cancel'
            }.Text`
          )}>
          <Select
            id="custom-reason-code"
            placeholder={t('Appointments.CancelAppointmentModal.Fields.Reason.PlaceHolder.Text')}
            searchable={false}
            clearable={false}
            options={customCancelReasons}
            value={
              cancelReason
                ? [
                    {
                      id: cancelReason,
                      label: cancelReason
                    }
                  ]
                : []
            }
            onChange={({ option, type }) => {
              setCancelReason(option.id as string)
            }}
          />
        </FormControl>
        {cancelReason === OTHER_CANCEL_REASON && (
          <FormControl
            label={t(
              `Appointments.CancelAppointmentModal.Fields.ReasonDescription.${
                reasonType == REASON_TYPES.decline ? 'Decline' : 'Cancel'
              }.Label.Text`
            )}>
            <Input
              name="reason"
              id="reason"
              multi
              autoComplete="off"
              value={cancelReasonDescription}
              onChange={e => {
                setCancelReasonDescription(e.currentTarget.value)
              }}
              maxLength={255}
            />
          </FormControl>
        )}
      </ModalBody>
      <ModalFooter>
        <Block display="flex" alignItems="center" justifyContent="space-between">
          {currentUser && currentUser.admin && reasonType != REASON_TYPES.decline ? (
            <Checkbox
              checked={notifyScheduler}
              onChange={e => {
                setNotifyScheduler(e.currentTarget.checked)
              }}
              label={t('Appointments.CancelAppointmentModal.Fields.NotifyScheduler.Label.Text')}
            />
          ) : (
            <div />
          )}
          <ErrorMessageButton
            errors={requiredFieldsPresent}
            onClick={onConfirmAction}
            isLoading={loading}
            statefulTooltipProps={{ placement: 'top' }}
            label={t(
              `Appointments.CancelAppointmentModal.ConfirmButton.${
                reasonType == REASON_TYPES.decline ? 'Decline' : 'Cancel'
              }`
            )}
          />
        </Block>
      </ModalFooter>
    </Modal>
  )
}

export default CancelAppointment
