import { Model } from './Model'

export interface Milestones extends Model {
  id?: string
  appointmentId?: string
  userId?: string
  address?: string
  latitude?: string
  longitude?: string
  facilityId: string
  checkedOutAt?: string
  checkedInAt?: string
  inGateAt?: string
  atDockAt?: string
  outDockAt?: string
  approvedAt?: string | Date
  declinedAt?: string | Date
  declineReason?: string
  customDeclineReasonId?: string
  status?: string
}

export const CHECKED_IN_AT = 'checkedInAt'
export const IN_GATE_AT = 'inGateAt'
export const AT_DOCK_AT = 'atDockAt'
export const OUT_DOCK_AT = 'outDockAt'
export const CHECKED_OUT_AT = 'checkedOutAt'

export const MILESTONE_KEYS = [CHECKED_IN_AT, IN_GATE_AT, AT_DOCK_AT, OUT_DOCK_AT, CHECKED_OUT_AT]
export const MILESTONE_DROP_KEYS = [CHECKED_IN_AT, IN_GATE_AT, CHECKED_OUT_AT]

export const MILESTONES_LABELS = {
  [CHECKED_IN_AT]: 'Check In',
  [IN_GATE_AT]: 'In Gate',
  [AT_DOCK_AT]: 'At Dock',
  [OUT_DOCK_AT]: 'Out Dock',
  [CHECKED_OUT_AT]: 'Check Out'
}

export const REQUESTED_STATUS = 'requested'
export const CHECKED_OUT_STATUS = 'checked out'
export const CHECKED_IN_STATUS = 'out dock'
export const IN_GATE_STATUS = 'at dock'
export const AT_DOCK_STATUS = 'in gate'
export const OUT_DOCK_STATUS = 'checked in'
export const SCHEDULED_STATUS = 'scheduled'
export const DECLINED_STATUS = 'declined'

export const AppointmentStatus = {
  REQUESTED: REQUESTED_STATUS,
  CHECKED_OUT: CHECKED_OUT_STATUS,
  CHECKED_IN: CHECKED_IN_STATUS,
  IN_GATE: IN_GATE_STATUS,
  AT_DOCK: AT_DOCK_STATUS,
  OUT_DOCK: OUT_DOCK_STATUS,
  SCHEDULED: SCHEDULED_STATUS,
  DECLINED: DECLINED_STATUS
}
