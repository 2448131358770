import React, { Dispatch } from 'react'
import { useTranslation } from 'react-i18next'
import { FlexGridItem } from 'baseui/flex-grid'
import { FormControl } from 'baseui/form-control'
import { HeadingXSmall, ParagraphSmall } from 'baseui/typography'
import Checkbox from 'components/ui/generic/Checkbox'

interface BulkUploadAndApiAllowDuplicatesSectionProps {
  appointmentPreference: any
  setAppointmentPreference: Dispatch<any>
  disabled: boolean
}

const BulkUploadAndApiAllowDuplicatesSection = ({
  appointmentPreference,
  setAppointmentPreference,
  disabled
}: BulkUploadAndApiAllowDuplicatesSectionProps) => {
  const { t } = useTranslation()

  return (
    <div style={{ marginBottom: '10px', width: '100%' }}>
      <HeadingXSmall marginTop="0" marginBottom="scale800">
        {t('AppointmentPreferences.Form.Fields.BulkUploadAndApiAllowDuplicates.Header.Text')}
      </HeadingXSmall>
      <>
        <ParagraphSmall marginTop="0" marginBottom="scale400">
          {t(
            'AppointmentPreferences.Form.Fields.BulkUploadAndApiAllowDuplicates.Subtitle.OrdersFileUpload'
          )}
        </ParagraphSmall>
        <FlexGridItem>
          <FormControl
            caption={t(
              'AppointmentPreferences.Form.Fields.BulkUploadAndApiAllowDuplicates.Fields.AllowDuplicates.Caption.Orders.Text'
            )}>
            <Checkbox
              disabled={disabled}
              checked={appointmentPreference.allowDuplicatesForOrderFileUpload}
              onChange={e => {
                setAppointmentPreference({
                  ...appointmentPreference,
                  allowDuplicatesForOrderFileUpload: e.currentTarget.checked
                })
              }}
              label={t(
                'AppointmentPreferences.Form.Fields.BulkUploadAndApiAllowDuplicates.Fields.AllowDuplicates.Label.Text'
              )}
            />
          </FormControl>
        </FlexGridItem>
        <ParagraphSmall marginTop="scale1000" marginBottom="scale400">
          {t(
            'AppointmentPreferences.Form.Fields.BulkUploadAndApiAllowDuplicates.Subtitle.OrdersAPI'
          )}
        </ParagraphSmall>
        <FlexGridItem>
          <FormControl
            caption={t(
              'AppointmentPreferences.Form.Fields.BulkUploadAndApiAllowDuplicates.Fields.AllowDuplicates.Caption.Orders.Text'
            )}>
            <Checkbox
              disabled={disabled}
              checked={appointmentPreference.allowDuplicatesForOrderApi}
              onChange={e => {
                setAppointmentPreference({
                  ...appointmentPreference,
                  allowDuplicatesForOrderApi: e.currentTarget.checked
                })
              }}
              label={t(
                'AppointmentPreferences.Form.Fields.BulkUploadAndApiAllowDuplicates.Fields.AllowDuplicates.Label.Text'
              )}
            />
          </FormControl>
        </FlexGridItem>
        <ParagraphSmall marginTop="scale1000" marginBottom="scale400">
          {t(
            'AppointmentPreferences.Form.Fields.BulkUploadAndApiAllowDuplicates.Subtitle.AppointmentsFileUpload'
          )}
        </ParagraphSmall>
        <FlexGridItem>
          <FormControl
            caption={t(
              'AppointmentPreferences.Form.Fields.BulkUploadAndApiAllowDuplicates.Fields.AllowDuplicates.Caption.Appointments.Text'
            )}>
            <Checkbox
              disabled={disabled}
              checked={appointmentPreference.allowDuplicatesForAppointmentFileUpload}
              onChange={e => {
                setAppointmentPreference({
                  ...appointmentPreference,
                  allowDuplicatesForAppointmentFileUpload: e.currentTarget.checked
                })
              }}
              label={t(
                'AppointmentPreferences.Form.Fields.BulkUploadAndApiAllowDuplicates.Fields.AllowDuplicates.Label.Text'
              )}
            />
          </FormControl>
        </FlexGridItem>
        <ParagraphSmall marginTop="scale1000" marginBottom="scale400">
          {t(
            'AppointmentPreferences.Form.Fields.BulkUploadAndApiAllowDuplicates.Subtitle.AppointmentsAPI'
          )}
        </ParagraphSmall>
        <FlexGridItem marginBottom="scale800">
          <FormControl
            caption={t(
              'AppointmentPreferences.Form.Fields.BulkUploadAndApiAllowDuplicates.Fields.AllowDuplicates.Caption.Appointments.Text'
            )}>
            <Checkbox
              disabled
              checked={appointmentPreference.allowDuplicatesForAppointmentApi}
              onChange={e => {
                setAppointmentPreference({
                  ...appointmentPreference,
                  allowDuplicatesForAppointmentApi: e.currentTarget.checked
                })
              }}
              label={t(
                'AppointmentPreferences.Form.Fields.BulkUploadAndApiAllowDuplicates.Fields.AllowDuplicates.Label.Text'
              )}
            />
          </FormControl>
        </FlexGridItem>
      </>
    </div>
  )
}

export default BulkUploadAndApiAllowDuplicatesSection
