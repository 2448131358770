export const MISSED_APPOINTMENT_CANCEL_REASON = 'no show'
export const CUSTOMER_REQUEST_CANCEL_REASON = 'customer request'
export const PRODUCTION_ISSUE_CANCEL_REASON = 'production issue'
export const TRAFFIC_CANCEL_REASON = 'traffic'
export const WEATHER_ISSUE_CANCEL_REASON = 'weather issue'
export const CARRIER_UNAVAILABLE_CANCEL_REASON = 'carrier unavailable'
export const USER_ERROR_CANCEL_REASON = 'user error'
export const GRACE_PERIOD_EXCEEDED_REASON = '30-minute grace period exceeded'
export const DAILY_LIMIT_EXCEEDED_REASON = 'daily limit exceeded'
export const OTHER_CANCEL_REASON = 'OTHER_CANCEL_REASON_OPTION'

export const DECLINE_REASON_TYPE = 'decline'
export const CANCEL_REASON_TYPE = 'cancel'
export const REASON_TYPES = {
  decline: DECLINE_REASON_TYPE,
  cancel: CANCEL_REASON_TYPE
}
