import authenticatedFetch from 'components/utils/authenticated-fetch'
import { CustomCancelReason } from '../models/CustomCancelReason'

const BASE_PATH = '/custom_cancel_reasons'

export const fetchAllCancelReasons = (reasonType?: string) => {
  const query = new URLSearchParams()
  if (reasonType) query.set('reason_type', String(reasonType))

  return authenticatedFetch({
    path: `${BASE_PATH}.json?${query.toString()}`,
    method: 'GET'
  })
}

export const fetchActiveCancelReasons = (reasonType?: string) => {
  const query = new URLSearchParams()
  if (reasonType) query.set('reason_type', String(reasonType))

  return authenticatedFetch({
    path: `${BASE_PATH}/get_assigned.json?${query.toString()}`,
    method: 'GET'
  })
}

export const fetchActiveCancelReasonsForOpenScheduling = (
  appointmentId: string
): Promise<[CustomCancelReason[], number]> => {
  return authenticatedFetch({
    path: `${BASE_PATH}/${appointmentId}/get_assigned_for_open_scheduling.json`,
    method: 'GET'
  })
}

export const saveAssignedCancelReasons = (customCancelReasonIds: string[], reasonType?: string) => {
  return authenticatedFetch({
    path: `${BASE_PATH}/assign.json`,
    method: 'POST',
    body: {
      customCancelReasonIds,
      reasonType
    }
  })
}

export const createCustomCancelReason = (cancelReason: CustomCancelReason) => {
  return authenticatedFetch({
    path: `${BASE_PATH}.json`,
    method: 'POST',
    body: { customCancelReason: cancelReason }
  })
}

export const updateCustomCancelReason = (cancelReason: CustomCancelReason) => {
  return authenticatedFetch({
    path: `${BASE_PATH}/${cancelReason.id}.json`,
    method: 'PUT',
    body: { customCancelReason: cancelReason }
  })
}

export const cancelAppointment = (id: string, openScheduling: boolean, body: any) => {
  return authenticatedFetch({
    path: `/appointments/${id}/cancel${openScheduling ? '_open_scheduling' : ''}.json`,
    method: 'POST',
    body: body
  })
}
